<template>
  <div class="detailBox">
    <div class="box">
      <div class="top">
        <div class="leftImg" @click="back()"></div>
        <!-- <div class="rightImg"></div> -->
      </div>
      <div class="detailTitle">{{ dataObj.title }}</div>
      <div class="nameTime">
        <div>
          <img :src="dataObj.creatorHeadImg" alt />
        </div>
        <div>{{ dataObj.timeShow }} | {{ dataObj.createTime }}</div>
      </div>
      <div class="content" v-html="dataObj.content"></div>
      <div class="line"></div>
      <div class="commentBox">
        <div class="commentContainer">
          <div class="commentNum">
            <div>评论区</div>
            <div>{{ commentList.length }}</div>
          </div>
          <div class="likeNum">
            {{ dataObj.likeNum }}赞 | {{ dataObj.forwardNum }}转发
          </div>
        </div>
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            offset="800"
            @load="onLoad"
          >
            <div v-for="item in commentList" :key="item.id">
              <div class="commentHearTitle">
                <div class="commentHear">
                  <img :src="item.headImg" alt />
                </div>
                <div class="commentTitle">{{ item.nickName }}</div>
              </div>
              <div class="commenContent">
                <div class="contentStyle">{{ item.content }}</div>
                <div class="replyTimeDel">
                  <div @click="showPopup1(item)">
                    回复
                    <img src="../../image/hf.png" alt />
                  </div>
                  <div>{{ item.createTime }}</div>
                  <div @click="showPopup(item)">
                    <img src="../../image/jbIcon.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <div class="detailFooter">
      <div class="footerTop">
        <div class="footerLeft">
          <img src="../../image/xpl.png" alt />
          <input
            ref="myInput"
            type="text"
            v-model="comment.content"
            placeholder="写个评论吧~"
          />
        </div>
        <span @click="addEvent()">发送</span>
      </div>
      <div class="footerRight">
        <div class="iconImg">
          <img src="../../image/xx.png" alt="" />{{ commentList.length }}
        </div>
        <div class="iconImg">
          <img
            src="../../image/dz.png"
            alt=""
            v-if="statObj.liked === 0"
            @click="dzEvent()"
          />
          <img
            src="../../image/dz1.png"
            alt=""
            v-if="statObj.liked === 1"
            @click="qxDzEvent()"
          />
          {{ dataObj.likeNum }}
        </div>

        <div class="iconImg">
          <img
            src="../../image/sc.png"
            alt=""
            v-if="statObj.collectd === 0"
            @click="scEvent()"
          />
          <img
            src="../../image/sc1.png"
            alt=""
            v-if="statObj.collectd === 1"
            @click="qxScEvent()"
          />
          {{ dataObj.collectNum }}
        </div>
        <div class="iconImg">
          <img src="../../image/fx.png" alt="" />{{ dataObj.forwardNum }}
        </div>
      </div>
    </div>
    <van-popup v-model="show" round position="bottom">
      <div class="jb" @click="jbEvent()">举报</div>
      <div class="qx" @click="show = false">取消</div>
    </van-popup>
    <van-popup
      v-model="show1"
      round
      position="bottom"
      :style="{ height: '90%' }"
    >
      <div>
        <div class="commentHearTitle">
          <div class="commentHear">
            <img :src="plObj.headImg" alt />
          </div>
          <div class="commentTitle">{{ plObj.nickName }}</div>
        </div>
        <div class="commenContent">
          <div class="contentStyle">{{ plObj.content }}</div>
          <div class="replyTimeDel">
            <div @click="hfEvent(plObj)">
              回复
              <img src="../../image/hf.png" alt />
            </div>
            <div>{{ plObj.createTime }}</div>
            <div @click="showPopup(plObj)">
              <img src="../../image/jbIcon.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="qbHf">全部回复</div>
       <div v-for="item in trendsCommentList" :key="item.id">
         <div class="commentHearTitle">
          <div class="commentHear">
            <img :src="item.headImg" alt />
          </div>
          <div class="commentTitle">{{ item.nickName }}</div>
         </div>
         <div class="commenContent">
          <div class="contentStyle">{{ item.content }}</div>
          <div class="replyTimeDel1">
            <!-- <div>
              回复
              <img src="../../image/hf.png" alt />
            </div> -->
            <div>{{ item.createTime }}</div>
            <div @click="showPopup(item)">
              <img src="../../image/jbIcon.png" alt="" />
            </div>
          </div>
         </div>
      </div>
    </van-popup>
       <!-- 回复 -->
    <van-popup v-model="hfShow" round position="bottom">
      <div class="footerTop">
        <div class="footerLeft">
          <img src="../../image/xpl.png" alt />
          <input
            type="text"
            v-model="comment.content"
            placeholder="写个评论吧~"
          />
        </div>
        <span @click="hfAddEvent()">发送</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  infoPageDetail,
  commentPage,
  commentAdd,
  statAdd,
  browseAdd,
  reportAdd,
  infoStat,
  statDel,
} from "../../api/lookAt";
import { Badge } from "vant";
import { Toast } from "vant";
import { Dialog } from "vant";
import { infoBrowseAdd } from "../../api/circle";
// import { serveData } from './serve'

import Cookies from "js-cookie";
export default {
  data() {
    return {
      id: this.$route.query.infoId,
      dataObj: {},
      comment: {
        business: 1,
        infoId: "", //资讯id
        parentId: "", //父评论id
        content: "", //评论内容
        pageNum: 1,
        pageSize: 10,
      },
      infoStat: {
        infoId: this.$route.query.infoId,
        operateType: 0, //0点赞 1收藏 2转发
        business: 1,
      },
      report: {
        infoId: this.$route.query.infoId,
        commentId: "", //评论id
      },
      statDelObj: {
        infoId: this.$route.query.infoId,
        operateType: 0, //0点赞 1收藏 2转发
        business: 1,
      },
      statObj: {},
      show: false,
      show1: false,
      hfShow:false,
      plObj: {},
      commentList: [],
      trendsCommentList:[],//回复评论列表
      total: 0,
      loading: false, //上拉加载
      finished: true, //加载与不加载的状态
      listTwo: [], //用于上拉加载时将数据合并
      isLoading: false, //下拉加载状态
      addObj: {
        infoId: "",
        type: 1,
      },
        commentForm:{
        content:'',
        parentId:'',
        infoId:'',
        business: 1,
      },
    };
  },
  mounted() {
    console.log(this.id);
    this.comment.infoId = this.id
    this.getInfoPageDetail();
    this.getcommentPage();
    this.getinfoStat();
  },
  methods: {
    getInfoPageDetail() {
      infoPageDetail({ id: this.id }).then((res) => {
        console.log(res, "资讯详情");
        this.dataObj = res.data.data;
        this.addObj.infoId = res.data.data.id;
        infoBrowseAdd(this.addObj).then((res) => {
          console.log(res, "资讯浏览记录******");
        });
      });
    },

    getinfoStat() {
      if (Cookies.get("token")) {
        infoStat({ infoId: this.$route.query.infoId, business: 1 }).then((res) => {
          console.log(res, "查询");
          this.statObj = res.data.data;
        });
      } else {
        this.statObj.collectd = 0;
        this.statObj.liked = 0;
      }
    },
    getcommentPage() {
      this.pageNum = 1;
      commentPage(this.comment).then((res) => {
        console.log(res, "评论");
        this.commentList = res.data.data.items;
        this.total = res.data.data.total - 0;
        if (this.commentList.length >= this.total) {
          //将返回数据长度与总条数进行比较控制加载不加载的状态
          this.finished = true;
        } else {
          this.finished = false;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    // 发送评论
    addEvent() {
      if (Cookies.get("token")) {
        commentAdd(this.comment).then((res) => {
          console.log(res, "新增评论");
          if (res.data.code === 0) {
            Toast("评论成功");
            this.getcommentPage();
            this.comment.content = "";
          }
        });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 下拉加载
    onRefresh() {
      setTimeout(() => {
        this.$toast("刷新成功");
        this.isLoading = false;
        this.getcommentPage();
      }, 1000);
    },
    // 上拉刷新
    onLoad() {
      this.pageNum++; //加载时页码递增
      commentPage(this.comment).then((res) => {
        if (res.data.code === 0) {
          this.listTwo = res.data.data.items; //将接口返回赋值data定义的数组
          this.total = res.data.data.total - 0; //将接口返回的总数据条数赋值data中定义的total
          this.listTwo.forEach((item) => {
            this.commentList.push(item);
            this.loading = false;
            // this.finished = false;
          });
          // 加载状态结束 状态更新为false
          this.loading = false;
          if (this.commentList.length >= this.total) {
            //将返回数据长度与总条数进行比较控制加载不加载的状态
            this.finished = true;
          }
        }
      });
    },
    // 举报弹框
    showPopup(item) {
      this.report.commentId = item.id;
      console.log(item);
      this.show = true;
    },
    // 举报
    jbEvent() {
      if (Cookies.get("token")) {
        Dialog.confirm({
          title: "举报",
          message: "确定要举报当前评论吗？",
        })
          .then(() => {
            reportAdd(this.report).then((res) => {
              console.log(res, "举报");
              if (res.data.code === 0) {
                Toast("举报成功");
                this.show = false;
                this.getInfoPageDetail();
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    commEvent() {},
    // 点赞
    dzEvent() {
      if (Cookies.get("token")) {
        this.infoStat.operateType = 0;
        statAdd(this.infoStat).then((res) => {
          console.log(res, "点赞");
          if (res.data.code === 0) {
            this.getInfoPageDetail();
            this.getinfoStat();
          }
        });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 取消点赞
    qxDzEvent() {
      if (Cookies.get("token")) {
        this.statDelObj.operateType = 0;
        statDel(this.statDelObj).then((res) => {
          console.log(res, "取消点赞");
          if (res.data.code === 0) {
            this.getInfoPageDetail();
            this.getinfoStat();
          }
        });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 收藏
    scEvent() {
      if (Cookies.get("token")) {
        this.infoStat.operateType = 1;
        statAdd(this.infoStat).then((res) => {
          console.log(res, "收藏");
          if (res.data.code === 0) {
            this.getInfoPageDetail();
            this.getinfoStat();
          }
        });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
    // 取消收藏
    qxScEvent() {
      if (Cookies.get("token")) {
        this.statDelObj.operateType = 1;
        statDel(this.statDelObj).then((res) => {
          console.log(res, "取消收藏");
          if (res.data.code === 0) {
            this.getInfoPageDetail();
            this.getinfoStat();
          }
        });
      } else {
        this.$router.push({ path: "/login" });
      }
    },
     // 回复弹框
    showPopup1(item) {
      console.log(item);
      this.plObj = item;
      this.show1 = true;
      this.comment.parentId = item.id
      commentPage(this.comment).then(res=>{
        console.log(res,'子机评论');
         this.trendsCommentList = res.data.data.items;
      })
    },
     // 评论回复
    hfEvent(item) {
      this.hfShow = true
      this.comment.content = ''
      console.log(item);
      this.comment.infoId = item.infoId;
      this.comment.parentId = item.id
    },
    // 发送回复
     hfAddEvent(){
      commentAdd(this.comment).then(res=>{
        console.log(res,'回复评论');
        if(res.data.code === 0){
          this.$toast("评论成功")
          this.hfShow = false
          commentPage(this.comment).then(res => {
          this.trendsCommentList = res.data.data.items;
          this.comment.content = ''
          })
        }
      })
    },
  },
};
</script>

<style scoped>
.top {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.leftImg {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.rightImg {
  width: 32px;
  height: 32px;
  background-image: url("../../image/dd.png");
  background-size: 100% 100%;
  margin-right: 32px;
}
.detailTitle {
  width: 686px;
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  margin-left: 32px;
  margin-top: 20px;
}
.nameTime {
  height: 48px;
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: 32px;
}
.nameTime div:nth-child(1) {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
}
.nameTime div:nth-child(1) img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.nameTime div:nth-child(2) {
  font-size: 28px;
  height: 36px;
  font-weight: 400;
  color: #999999;
  margin-left: 24px;
}
.content {
  width: 686px;
  font-size: 32px;
  font-weight: 400;
  color: #222222;
  margin-left: 32px;
  margin-top: 32px;
  line-height: 70px;
}
.content ::v-deep img {
  width: 100% !important;
}
.line {
  width: 686px;
  height: 0px;
  opacity: 1;
  border: 1px solid #ebedf0;
  margin-left: 32px;
  margin-top: 32px;
}
.commentContainer {
  width: 686px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 32px;
  margin-top: 32px;
}
.commentNum {
  height: 64px;
  display: flex;
  font-size: 32px;
  font-weight: 500;
  color: #222222;
  line-height: 64px;
}
.commentNum div:nth-child(2) {
  margin-left: 16px;
}
.likeNum {
  font-size: 24px;
  font-weight: 500;
  color: #999999;
}
.commentHearTitle {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.commentHear {
  width: 64px;
  height: 64px;

  margin-left: 32px;
}
.commentHear img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.commentTitle {
  font-size: 28px;
  font-weight: 500;
  color: #222222;
  margin-left: 16px;
}
.commenContent {
  width: 606px;
  margin-left: 112px;
  margin-top: 8px;
}
.contentStyle {
  font-size: 28px;
  font-weight: 400;
  color: #222222;
}
.replyTimeDel,.replyTimeDel1 {
  display: flex;
  align-items: center;
  margin-top: 16px;
  /* margin-bottom: 38px; */
}
.replyTimeDel div:nth-child(1) {
  height: 52px;
  background: #f7f8fa;
  border-radius: 806px 806px 806px 806px;
  font-size: 24px;
  font-weight: 400;
  color: #222222;
  line-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.replyTimeDel div:nth-child(1) img {
  width: 20px;
  height: 20px;
  display: flex;
  /* align-items: center; */
}
.replyTimeDel div:nth-child(2) {
  width: 450px;
  font-size: 24px;
  font-weight: 400;
  color: #999999;
  margin-left: 32px;
}
.replyTimeDel div:nth-child(3) {
  font-size: 24px;
  color: #999999;
}
.replyTimeDel div:nth-child(3) img {
  width: 24px;
  height: 24px;
}
.replyTimeDel1{
  display:flex;
  justify-content: space-between;
   font-size: 24px;
  font-weight: 400;
  color: #999999;
}
.replyTimeDel1 img{
  width: 24px;
  height: 24px;
}
.box {
  margin-bottom: 140px;
}
/* .detailFooter {
  width: 750px;
  height: 100px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border-top: 2px solid #ebedf0;
  position: fixed;
  bottom: 0px;
  display: flex;
  align-items: center;
}

.footerLeft {
  width: 336px;
  height: 64px;
  background: #f7f8fa;
  border-radius: 1272px 1272px 1272px 1272px;
  opacity: 1;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.footerLeft img {
  width: 28px;
  height: 28px;
  margin-left: 24px;
}
.footerLeft input {
  outline: none;
  width: 190px;
  border: none;
  font-size: 28px;
  background: #f7f8fa;
  margin-left: 8px;
} */
/* .footerLeft span {
  display: inline-block;
  margin-right: 5px;
  font-size: 24px;
  color: royalblue;
}
.footerRight img {
  width: 48px;
  height: 48px;
}
/deep/.van-badge--fixed {
  top: 20px;
}
/deep/.van-badge {
  color: red;
  background-color: transparent;
}
.van-badge__wrapper {
  margin-left: 35px;
} */
.commentBox {
  position: relative;
}
.detailFooter {
  /* position: absolute; */
  /* bottom: 0px; */
  width: 750px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: fixed;
  bottom: 0px;
}
.footerTop {
  display: flex;
  align-items: center;
  margin-bottom:32px;
  margin-top:32px;
}
.footerLeft {
  width: 610px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px;
  opacity: 1;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.footerLeft img {
  width: 28px;
  height: 28px;
  margin-left: 24px;
}
.footerLeft input {
  outline: none;
  width: 500px;
  border: none;
  font-size: 28px;
  background: #f7f8fa;
  margin-left: 8px;
}
.footerTop span {
  display: inline-block;
  margin-right: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #0957c3;
  margin-left: 28px;
}
.footerRight {
  margin-top: 58px;
  margin-bottom: 26px;
  display: flex;
  justify-content: space-around;
}
.footerRight img {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}
.iconImg {
  font-size: 24px;
  font-weight: 400;
  color: #0b1526;
  display: flex;
  align-items: center;
}
.jb {
  width: 750px;
  height: 108px;
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  line-height: 108px;
  border-bottom: 1px solid #ebedf0;
}
.qx {
  width: 750px;
  height: 108px;
  font-size: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 108px;
}
.qbHf {
  color: rgba(0, 0, 0, 0.9);
  font-size: 32px;
  font-weight: 600;
  margin-left: 32px;
  margin-top: 32px;
}
</style>
